import { useEffect, useState } from "react";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

import axios from "../../api/AxiosHttp";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";

const PrimisDashboard = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (companies.length == 0) {
      getCompanies();
    }
  }, [companies]);

  const getCompanies = async () => {
    const endPoint = "api/internal_primis_data";

    try {
      const result = await axios.get(endPoint);
      setCompanies(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultSortInfo = { name: "videoTitle", dir: 1 };

  // ReactDataGrid filter for columns
  const filterValue = [
    { name: "videoTitle", operator: "startsWith", type: "string", value: "" },
    {
      name: "content_imps",
      operator: "eq",
      type: "number",
      value: null,
      filterEditor: NumberFilter,
    },
    {
      name: "client_views",
      operator: "eq",
      type: "number",
      value: null,
      filterEditor: NumberFilter,
    },
  ];

  const columns = [
    { name: "videoTitle", header: "Video Title", defaultFlex: 2 },
    {
      name: "content_imps",
      header: "Primis Impressions",
      defaultFlex: 0.5,
      type: "number",
      filterEditor: NumberFilter,
    },
    {
      name: "client_views",
      header: "Client Views",
      defaultFlex: 0.5,
      type: "number",
      filterEditor: NumberFilter,
    }, 
  ];

  return (
    <>
      <BaseTitle heading="Video Network" />

      <BaseTable
        className="mt-8"
        style={{ minHeight: 740 }}
        defaultSortInfo={defaultSortInfo}
        loading={loading}
        loadingText="Fetching data"
        idProperty="companies-table"
        pagination={true}
        enableFiltering
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={companies}
      />
    </>
  );
};

export default PrimisDashboard;
