import { Link } from "react-router";

import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";
import BarChart from "../../components/charts/BarChart";
import { formatThousands, toCamel } from "../../utils/Utils";

const ClientDashboardDetails = ({ data }) => {
  const { top_article_views: topArticleViews, top_video_views: topVideoViews } =
    data;

  const articleTable = [
    {
      name: "name",
      header: "Name",
      defaultFlex: 6,
      render: ({ data }) => {
        return (
          <Link
            to={"/analytics/" + data.active_company_id}
            className="underline text-primary"
          >
            {data.name}
          </Link>
        );
      },
    },
    {
      name: "article_view_count",
      header: "Article Views",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.article_view_count);
      },
    },
  ];

  const videoTable = [
    {
      name: "name",
      header: "Name",
      defaultFlex: 6,
      render: ({ data }) => {
        return (
          <Link
            to={"/analytics/" + data.active_company_id}
            className="underline text-primary"
          >
            {data.name}
          </Link>
        );
      },
    },
    {
      name: "video_view_count",
      header: "Video Views",
      defaultFlex: 1.7,
      render: ({ data }) => {
        return formatThousands(data.video_view_count);
      },
    },
  ];

  const articles = [];

  topArticleViews?.forEach((data) => {
    articles.push([toCamel(data.name), data.article_view_count]);
  });

  const articleChartOptions = {
    plotOptions: {
      series: {
        minPointLength: 3,
      },
    },
    xAxis: {
      type: "category",
      crosshair: true,
      accessibility: {
        description: "Articles",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Articles",
        data: articles?.sort((a, b) => b[1] - a[1]),
        colors: [
          "#003f5c",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#d45087",
          "#f95d6a",
          "#ff7c43",
          "#ffa600",
          "#7682d9",
          "#6178c7",
        ],
        colorByPoint: true,
      },
    ],
  };

  const videos = [];

  topVideoViews?.forEach((data) => {
    videos.push([toCamel(data.name), parseInt(data.video_view_count)]);
  });

  const videoChartOptions = {
    plotOptions: {
      series: {
        minPointLength: 3,
      },
    },
    xAxis: {
      type: "category",
      crosshair: true,
      accessibility: {
        description: "Videos",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Videos",
        data: videos?.sort((a, b) => b[1] - a[1]),
        colors: [
          "#702727",
          "#802728",
          "#8f2728",
          "#9f2728",
          "#af2627",
          "#be2526",
          "#ce2324",
          "#de2121",
          "#ea2728",
          "#ff2626",
        ].reverse(),
        colorByPoint: true,
      },
    ],
  };


  return (
    <>
      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
            Top Companies by Article Views
          </h4>
          {topArticleViews?.length > 0 ? (
            <BarChart
              options={articleChartOptions}
              className="!grow-0 mt-auto"
            />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>
        <BaseCard className="flex flex-col">
          <h4 className="text-xl mb-4 uppercase text-violet font-bold w-fit">
            Top Companies by Video Views
          </h4>
          {topVideoViews?.length > 0 ? (
            <BarChart options={videoChartOptions} className="!grow-0 mt-auto" />
          ) : (
            <div className="w-full h-[340px] flex flex-col items-center justify-center text-sm">
              Not enough data
            </div>
          )}
        </BaseCard>
      </div>

      <div className="grid gap-5 md:grid-cols-2">
        <BaseCard>
          <BaseTable
            idProperty="top-article-views"
            dataSource={topArticleViews}
            columns={articleTable}
            style={{ minHeight: 445 }}
            className="no-border"
            scrollHide={false}
          />
        </BaseCard>
        <BaseCard className="flex flex-col">
          <BaseTable
            idProperty="top-video-views"
            dataSource={topVideoViews}
            columns={videoTable}
            style={{ minHeight: 445 }}
            className="no-border"
            scrollHide={false}
          />
        </BaseCard>
      </div>
    </>
  );
};

export default ClientDashboardDetails;
