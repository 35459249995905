import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompanyId } from "../redux/authSlice";

function DropdownCompany({ align }) {
  const { userData, currentCompanyId } = useSelector((state) => state.auth);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const handleCompanyChange = (e) => {
    const companyID = e.currentTarget.getAttribute("data-company-id");
    setDropdownOpen(false);
    dispatch(setCurrentCompanyId(parseInt(companyID)));
    navigate(`/analytics/${companyID}`);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const [defaultCompany] = userData.active_companies?.filter(
    (company) => company.id === currentCompanyId
  );

  return (
    <div className="relative">
      <button
        ref={trigger}
        className="grow flex items-center truncate"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className="w-8 h-8 rounded-full mr-2 object-left object-cover"
          src={defaultCompany.url}
          width="32"
          height="32"
          alt={defaultCompany.name}
        />
        <div className="truncate">
          <span className="text-sm font-medium">{defaultCompany.name}</span>
        </div>

        <svg
          className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-60 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <ul
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {userData.active_companies.map((company, index) => (
            <li key={index}>
              <button
                className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3 w-full"
                type="button"
                data-company-id={company.id}
                onClick={handleCompanyChange}
              >
                <div className="flex items-center justify-between">
                  <div className="grow flex items-center truncate">
                    <img
                      className="w-7 h-7 rounded-full mr-2 object-left object-cover"
                      src={company.url}
                      width="28"
                      height="28"
                      alt="Channel 01"
                    />
                    <div className="truncate">{company.name}</div>
                  </div>
                  {currentCompanyId === company.id && (
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                  )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
}

export default DropdownCompany;
