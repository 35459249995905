import { createSlice } from "@reduxjs/toolkit";

export const primisSlice = createSlice({
  name: "primis",
  initialState: {
    primisVideoData: {},
  },
  reducers: {
    updatePrimisVideoData: (state, action) => {
      state.primisVideoData = action.payload;
    },
  },
});

export const { updatePrimisVideoData } = primisSlice.actions;

export default primisSlice.reducer;
