import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import PropTypes from "prop-types";

import axios from "../../api/AxiosHttp";
import BaseCard from "../../components/base/BaseCard";
import BaseStats from "../../components/base/BaseStats";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";
import DoughnutChart from "../../components/charts/DoughnutChart";
import Tippy from "../../lib/Tipy";
import VideosSummaryChart from "../../partials/analytics/videos/VideosSummaryChart";
import VideosTable from "../../partials/analytics/videos/PrimisVideosTable";
import { updatePrimisVideoData } from "../../redux/primisSlice";
import { toCamel, formatDate, minTommss } from "../../utils/Utils";

// Set partial prop true to only show summary section
const Primis = ({ partial = false }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { primisVideoData } = useSelector((state) => state.primis);
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    getSummary();
    Tippy("[data-tippy-content]");
  }, [companyData]);

  const logActivity = () => {
    if (
      !partial &&
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_primis_videos_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_primis_videos",
        type: "company",
      });
      sessionStorage.setItem("view_primis_videos_" + companyData.company_id, true);
    }
  };

  // Function to get video data from API and update redux store if older than 5 minutes
  const getSummary = async () => {
    logActivity();

    if (
      primisVideoData &&
      primisVideoData.active_company_id &&
      primisVideoData.active_company_id == id &&
      Date.now() - primisVideoData.timestamp < 300000
    ) {
      return;
    }

    setLoadingPage(true);

    dispatch(updatePrimisVideoData({}));

    try {
      const endPoint = `/api/companies/active/${id}/primis`;
      const { data } = await axios.get(endPoint);

      dispatch(updatePrimisVideoData({ ...data, timestamp: Date.now() }));
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      dispatch(updatePrimisVideoData({ status: 0 }));
    }
  };

  const visitorCountriesTable = [
    { name: "key", header: "Country", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const externalSourcesTable = [
    { name: "key", header: "Sources", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  return (
    <>
      <BaseTitle
        heading="Video Network"
        type={!partial ? "h1" : "h2"}
        subtitle={!partial && companyData.name}
        linkTo={partial && `/analytics/${id}/primis`}
        className={partial ? "hidden" : ""}
        show={partial ? "false" : "true"}
      />
      {!loadingPage ? (
        <>
          {primisVideoData &&
          Object.keys(primisVideoData).length > 0 &&
          primisVideoData?.status !== 0 ? (
            <>
              <div className={`grid grid-cols-3 gap-8 ${partial ? "hidden" : ""}`}>
                <BaseStats
                  name="Video Network Views"
                  subtext={
                    "(As of " +
                    formatDate(
                      new Date().setDate(new Date().getDate() - 3)
                    ) +
                    ")"
                  }
                  number={primisVideoData.primis_views}
                  data-tippy-content="Sourced from our Video Network, updated daily, the total number of views your videos have received since they were published."
                />
                <div className="flex col-span-2">
                  <BaseCard>
                    <p>
                    Maximise your reach with our <strong>Video Network</strong>, where our video content is syndicated across the Proactive network of leading financial news. Your message is amplified to the vast investor audience using Proactive, ensuring unparalleled visibility and engagement. This is video distribution at its most powerful.
                    </p>
                  </BaseCard>
                </div>
              </div>
              {!partial && (
                <>
                  <div className="grid grid-cols-12 gpa-0 md:gap-8 xl:mt-8 hidden">
                    <div className="col-span-12 md:col-span-6 hidden">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from our Video Network"
                        >
                          Top 5 Viewer Countries
                        </h4>
                        <BaseTable
                          idProperty="visitor-countries"
                          dataSource={
                            primisVideoData.countries ? primisVideoData.countries : []
                          }
                          columns={visitorCountriesTable}
                          style={{ minHeight: 250 }}
                          className="no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>

                    <div className="col-span-12 md:col-span-6 hidden">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from our Video Network and updated weekly, this is an averaged total of all videos and their external referral sources of traffic."
                        >
                          Top 5 External Referral
                        </h4>
                        <BaseTable
                          idProperty="traffic-source"
                          dataSource={
                            primisVideoData.traffic_source
                              ? primisVideoData.traffic_source
                              : []
                          }
                          columns={externalSourcesTable}
                          style={{ minHeight: 250 }}
                          className="no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <BaseCard className="mb-8">There is no data for videos.</BaseCard>
          )}

          {!partial &&
            primisVideoData?.items &&
            primisVideoData?.status !== 0 && (
              <VideosTable tableData={primisVideoData.items} />
            )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

Primis.propTypes = {
  partial: PropTypes.bool,
};

export default Primis;
