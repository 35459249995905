import { useEffect, useState } from "react";
import axios from "../../api/AxiosHttp";
import { ThreeDots } from "react-loader-spinner";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";

const ActivityOverview = () => {
  const [activityOverview, setActivityOverview] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    fetchActivityOverview();
  }, []);

  const fetchActivityOverview = async () => {
    setLoadingPage(true);
    try {
      const endPoint = `/api/internal_client_activity`;
      const { data } = await axios.get(endPoint);

      setActivityOverview(data);
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      setLoadingPage(false);
    }
  };

  // ReactDataGrid settings
  const defaultSortInfo = { name: "timestamp", dir: -1 };

  const defaultFilterValue = [
    { name: "company_name", operator: "contains", type: "string", value: "" },
    { name: "email", operator: "contains", type: "string", value: "" },
    { name: "region", operator: "inlist", type: "select", value: "" },
    { name: "activity", operator: "contains", type: "string", value: "" },
  ];

  const activityTable = [
    {
      name: "timestamp",
      header: "Date",
      defaultFlex: 0.15,
      render: ({ data }) => {
        return data.date;
      },
    },
    {
      name: "company_name",
      header: "Company Name",
      defaultFlex: 0.2,
    },
    {
      name: "email",
      header: "Email",
      defaultFlex: 0.2,
    },
    {
      name: "region",
      header: "Region",
      defaultFlex: 0.1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All Regions",
        dataSource: [
          { id: "UK", label: "UK" },
          { id: "US", label: "US" },
          { id: "CA", label: "CA" },
          { id: "AU", label: "AU" },
          { id: "Other", label: "Other" },
        ],
      },
    },
    {
      name: "activity",
      header: "Activity",
      defaultFlex: 0.3,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All Activity",
        dataSource: [
          { id: "User Login", label: "User Login" },
          { id: "Viewed Article", label: "Viewed Article" },
          { id: "Viewed Video", label: "Viewed Video" },
          { id: "Viewed Primis", label: "Viewed Primis" },
          { id: "Viewed Distribution", label: "Viewed Distribution" },
          { id: "Viewed Contact Us", label: "Viewed Contact Us" },
          { id: "Clicked Contact Email", label: "Clicked Contact Email" },
          {
            id: "Exported Corporate Visitors Table",
            label: "Exported Corporate Visitors Table",
          },
          {
            id: "Exported Performance Summary Table",
            label: "Exported Performance Summary Table",
          },
          { id: "Exported PDF", label: "Exported PDF" },
        ],
      },
      render: ({ data }) => {
        return <div title={data.activity}>{data.activity}</div>;
      },
    },
  ];

  return (
    <>
      <BaseTitle heading="Activity Overview" />

      {!loadingPage ? (
        <>
          {activityOverview && Object.keys(activityOverview).length > 0 ? (
            <BaseTable
              idProperty="activityOverview"
              dataSource={activityOverview ? activityOverview : []}
              columns={activityTable}
              defaultSortInfo={defaultSortInfo}
              style={{ minHeight: 740 }}
              scrollHide={false}
              enableFiltering
              defaultFilterValue={defaultFilterValue}
              pagination={true}
            />
          ) : (
            <BaseCard>There is no data for client activity.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default ActivityOverview;
