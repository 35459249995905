import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import axios from "../../api/AxiosHttp";
import Select from "react-select";
import SelectDefaultValue from "../../components/SelectDefaultValue";
import Noty from "../../lib/Noty";

import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import BaseButton from "../../components/base/BaseButton";

const UserDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const name = useRef("");
  const email = useRef("");
  const password = useRef("");

  const [userData, setUserData] = useState({});
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [assignedCompanies, setAssignedCompanies] = useState([]);
  const [key, setKey] = useState(Date.now());
  const [submitloading, setSubmitloading] = useState(false);

  // Call functions on first load
  useEffect(() => {
    getAccount();
    getCompanies();
  }, []);

  // Get account details to prefil the form fields including the companies select
  const getAccount = async () => {
    const endPoint = "/api/users/" + id;
    const result = await axios.get(endPoint);

    setUserData(result.data);

    // Get the list of assigned companies to this user in formatting the React Select needs
    setAssignedCompanies(
      result.data.active_companies.map((company) => ({
        value: company.company_meta.id,
        label: company.company_meta.name,
      }))
    );

    // Updating the key and passing it to the child component to reload it
    setKey(Date.now());
  };

  // Call the active companies API to get a list for the React Select
  const getCompanies = async () => {
    const endPoint = "/api/companies/active";
    const result = await axios.get(endPoint);
    setActiveCompanies(
      result.data.map((company) => ({
        value: company.id,
        label: company.name,
      }))
    );
  };

  // Form submit handler, send all the data to user API to edit account
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitloading(true);

    // Get form data from ref + user ID
    const refFormData = {
      user_id: id,
      name: name.current.value,
      email: email.current.value,
    };

    // If password field is used then add into object
    if (password.current.value) {
      refFormData.password = password.current.value;
    }

    // Use FormData object to get entries for all the companies selected in dropdown
    const companiesSelectDropdown = new FormData(e.currentTarget);
    if (companiesSelectDropdown.getAll("companies") != "") {
      companiesSelectDropdown.append(
        "companies",
        JSON.stringify(companiesSelectDropdown.getAll("companies"))
      );
    }

    // Merge both form objects into one
    const formData = {
      ...refFormData,
      ...Object.fromEntries(companiesSelectDropdown.entries()),
    };

    const endPoint = "/api/users/" + id;

    // Axios call to backend to update user ".put" to update
    try {
      await axios.put(endPoint, formData);
      new Noty({
        text: "User was successfully updated",
        type: "success",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();

      navigate("/users");
    } catch (error) {
      setSubmitloading(false);
      const notymessage =
        error.response.data.error[Object.keys(error.response.data.error)[0]];
      new Noty({
        type: "error",
        text: notymessage,
      }).show();
    }
  };

  const regionList = [
    { value: "UK", label: "UK" },
    { value: "US", label: "US" },
    { value: "CA", label: "CA" },
    { value: "AU", label: "AU" },
    { value: "Other", label: "Other" },
  ];

  return (
    <>
      <BaseTitle heading="User Details" />
      <BaseCard>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid gap-5 md:grid-cols-2">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Name
              </label>
              <input
                id="name"
                className="form-input w-full"
                type="text"
                defaultValue={userData.name}
                ref={name}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                className="form-input w-full"
                type="email"
                defaultValue={userData.email}
                ref={email}
                autoComplete="false"
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  className="form-input w-full"
                  type="password"
                  ref={password}
                  autoComplete="false"
                />
              </div>
            </div>
            {userData.roles &&
              userData.roles[0].name === process.env.REACT_APP_CLIENT && (
                <>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="companies"
                    >
                      Companies
                    </label>
                    <SelectDefaultValue
                      key={key}
                      activeCompanies={activeCompanies}
                      assignedCompanies={assignedCompanies}
                      id="companies"
                      name="companies"
                      required={true}
                    />
                    <div className="text-xs mt-1">
                      Only select companies for client role
                    </div>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="region"
                    >
                      Region
                    </label>

                    <Select
                      id="region"
                      className="w-full fselect"
                      defaultValue={{
                        value: userData.region,
                        label: userData.region,
                      }}
                      name="region"
                      options={regionList}
                      required={true}
                    />
                  </div>
                </>
              )}
            <div className="col-span-2">
              <BaseButton
                label="Save user"
                type="submit"
                loading={submitloading}
              />
            </div>
          </div>
        </form>
      </BaseCard>
    </>
  );
};

export default UserDetails;
