import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import generator from "generate-password-browser";
import Select from "react-select";
import axios from "../../api/AxiosHttp";
import { useSelector } from "react-redux";
import BaseTitle from "../../components/base/BaseTitle";
import BaseCard from "../../components/base/BaseCard";
import BaseButton from "../../components/base/BaseButton";
import Noty from "../../lib/Noty";

const AddUser = () => {
  const { userData } = useSelector((state) => state.auth);

  const [activeCompanies, setActiveCompanies] = useState([]);
  const [password, setPassword] = useState("");
  const [hideCompaniesField, setHideCompaniesField] = useState(false);
  const [submitloading, setSubmitloading] = useState(false);

  const passwordRef = useRef();

  const navigate = useNavigate();

  // Get companies list and generate password on first load
  useEffect(() => {
    getCompanies();
    generatePassword();
  }, []);

  // Get a list of active companies and update the state
  // ReactSelect only wants value and label
  const getCompanies = async () => {
    const endPoint = "/api/companies/active";
    try {
      const result = await axios.get(endPoint);
      const companies = result.data.map((company) => ({
        value: company.id,
        label: company.name,
      }));
      setActiveCompanies(companies);
    } catch (err) {
      // handle error
    }
  };

  // Use the generate password package to randomly generate a password and update state
  const generatePassword = () => {
    const pwd = generator.generate({
      length: 18,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    setPassword(pwd);
  };

  // Copy password function that only works on HTTPS and grabs value from passwordRef
  const copyPassword = (e) => {
    e.preventDefault();

    navigator.clipboard.writeText(passwordRef.current.value);
  };

  // Form submit handler, send all the data to user API to create an account
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitloading(true);

    const formData = new FormData(e.currentTarget);
    if (formData.getAll("companies") != "") {
      formData.append(
        "companies",
        JSON.stringify(formData.getAll("companies"))
      );
    }
    let formObject = Object.fromEntries(formData.entries());

    const endPoint = "/api/users";

    try {
      await axios.post(endPoint, formObject);
      new Noty({
        text: "User was successfully added",
        type: "success",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
      navigate("/users");
    } catch (error) {
      setSubmitloading(false);
      const notymessage =
        error.response.data.error[Object.keys(error.response.data.error)[0]];
      new Noty({
        type: "error",
        text: notymessage,
      }).show();
    }
  };

  // Roles list for the form dropdown
  var roles = [{ value: "3", label: `${process.env.REACT_APP_CLIENT}` }];

  // Add more roles to role list if super admin
  if (userData.role === process.env.REACT_APP_ADMIN) {
    roles.push(
      { value: "2", label: process.env.REACT_APP_STAFF },
      { value: "1", label: process.env.REACT_APP_ADMIN }
    );
  }

  // Conditional logic for showing companies field
  const onRoleChange = (selectedOption) => {
    setHideCompaniesField(
      selectedOption.label !== process.env.REACT_APP_CLIENT
    );
  };

  const regionList = [
    { value: "UK", label: "UK" },
    { value: "US", label: "US" },
    { value: "CA", label: "CA" },
    { value: "AU", label: "AU" },
    { value: "Other", label: "Other" },
  ];

  return (
    <>
      <BaseTitle heading="Add User" />
      <BaseCard>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-5 md:grid-cols-2">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Name
              </label>
              <input
                id="name"
                name="name"
                className="form-input w-full"
                type="text"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                className="form-input w-full"
                type="email"
                required
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  className="form-input w-full"
                  type="text"
                  name="password"
                  value={password}
                  ref={passwordRef}
                  readOnly
                />
                <button
                  className="absolute inset-0 left-auto group"
                  aria-label="Copy"
                  onClick={copyPassword}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-3 mr-2 icon icon-tabler icon-tabler-copy"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#94a3b8"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="8" y="8" width="12" height="12" rx="2" />
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                  </svg>
                </button>
              </div>
              <div className="text-xs mt-1">
                This password is randomly generated
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="role">
                Role
              </label>
              <Select
                id="role_id"
                className="w-full fselect"
                options={roles}
                name="role_id"
                required
                onChange={onRoleChange}
              />
            </div>

            {!hideCompaniesField && (
              <>
                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="companies"
                  >
                    Companies
                  </label>
                  <Select
                    id="companies"
                    className="w-full fselect"
                    isMulti
                    options={activeCompanies}
                    name="companies"
                    required={true}
                  />
                  <div className="text-xs mt-1">
                    You can select multiple companies if required
                  </div>
                </div>

                <div>
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="region"
                  >
                    Region
                  </label>
                  <Select
                    id="region"
                    className="w-full fselect"
                    options={regionList}
                    name="region"
                    required={true}
                  />
                </div>
              </>
            )}
          </div>
          <BaseButton
            className="mt-6"
            label="Add User"
            type="submit"
            loading={submitloading}
          />
        </form>
      </BaseCard>
    </>
  );
};

export default AddUser;