import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";
import axios from "../../api/AxiosHttp";
import Noty from "../../lib/Noty";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { formatDateToTimestamp } from "../../utils/Utils";
import ModalFeedback from "../../components/ModalFeedback";
import BaseTitle from "../../components/base/BaseTitle";
import BaseTable from "../../components/base/BaseTable";
import BaseCard from "../../components/base/BaseCard";

const ActiveCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [dangerModalData, setDangerModalData] = useState({});

  const { userData } = useSelector((state) => state.auth);

  // Call getCompanies function on first load and evryime companies state updates
  useEffect(() => {
    if (companies.length == 0) {
      getCompanies();
    }
  }, [companies]);

  // Function to call endpoint to get a list of active companies
  const getCompanies = async () => {
    try {
      const endPoint = "api/companies/active";
      const result = await axios.get(endPoint);
      setCompanies(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Open Modal with a cancel or confirm disable action
  const handleDisableDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const id = e.currentTarget.getAttribute("data-id");
    const status = e.currentTarget.getAttribute("data-status");
    const name = e.currentTarget.getAttribute("data-name");

    setDangerModalData({ id, status, name });
    setDangerModalOpen(true);
  };

  // Function to disable active companies
  const handleDisable = async (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const status = e.currentTarget.getAttribute("data-status");
    const endPoint = "api/companies/active";

    try {
      await axios.post(endPoint, { id, status });

      setDangerModalOpen(false);

      new Noty({
        text: "Company was successfully disabled",
        type: "success",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();

      getCompanies();
    } catch (error) {
      var er = error.response.data.error;
      var notymessage = er[Object.keys(er)[0]];

      new Noty({
        type: "error",
        text: notymessage,
      }).show();
    }
  };

  const runNewsRetrival = async (e) => {
    const name = e.currentTarget.getAttribute("data-name");
    const id = e.currentTarget.getAttribute("data-id");

    const endPoint = `api/companies/active/${id}/news`;

    try {
      await axios.put(endPoint);

      new Noty({
        text: `Manual news retrival triggered for ${name}`,
        type: "success",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
    } catch (error) {
      new Noty({
        type: "error",
        text: "Something went wrong, check logs.",
      }).show();
    }
  };

  const runVideoRetrival = async (e) => {
    const name = e.currentTarget.getAttribute("data-name");
    const id = e.currentTarget.getAttribute("data-id");

    const endPoint = `api/companies/active/${id}/video`;

    try {
      await axios.put(endPoint);

      new Noty({
        text: `Manual video retrival triggered for ${name}`,
        type: "success",
        timeout: 3000,
        modal: true,
        progressBar: true,
      }).show();
    } catch (error) {
      new Noty({
        type: "error",
        text: "Something went wrong, check logs.",
      }).show();
    }
  };

  // ReactDataGrid settings
  const defaultSortInfo = { name: "name", dir: 1 };

  // ReactDataGrid filters for columns
  const filterValue = [
    { name: "name", operator: "startsWith", type: "string", value: "" },
    {
      name: "id",
      operator: "eq",
      type: "number",
      value: null,
      filterEditor: NumberFilter,
    },
    { name: "type", operator: "startsWith", type: "string", value: "" },
    { name: "sector_type", operator: "inlist", type: "select", value: "" },
  ];

  const sectors =
    companies && Object.keys(companies).length > 0
      ? companies.map((item) => item.sector_type)
      : [];

  const uniqueSectors = sectors
    .filter((item, index) => sectors.indexOf(item) === index)
    .sort();

  // ReactDataGrid Column with custom render
  const columns = [
    {
      name: "name",
      header: "Name",
      defaultFlex: 1.1,
      render: ({ data }) => {
        const active =
          Date.now() - formatDateToTimestamp(data.created) < 600000
            ? false
            : true;
        return (
          <>
            {active ? (
              <Link
                to={"/analytics/" + data.active_company_meta_id}
                className="underline text-primary"
              >
                {data.name}
              </Link>
            ) : (
              <span>
                {data.name}{" "}
                <span className="bg-dark-grey text-xs py-[0.2rem] px-[0.3rem] ml-2 text-white rounded-lg">
                  processing
                </span>
              </span>
            )}
          </>
        );
      },
    },

    {
      name: "id",
      header: "Company ID",
      defaultFlex: 0.5,
      type: "number",
      filterEditor: NumberFilter,
    },

    {
      name: "type",
      header: "Company Type",
      defaultFlex: 0.5,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All Types",
        dataSource: [
          { id: "Client", label: "Client" },
          { id: "Non-Client", label: "Non-Client" },
          { id: "Prospect", label: "Prospect" },
        ],
      },
    },
    {
      name: "sector_type",
      header: "Sector",
      defaultFlex: 0.5,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "All Sectors",
        dataSource: uniqueSectors.map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    { name: "expiry", header: "Expiry", defaultFlex: 0.35 },
    {
      name: "media_pack",
      header: "Media Pack",
      defaultFlex: 0.3,
      render: ({ value }) => (value == 1 ? "Yes" : "No"),
    },
    {
      name: "research",
      header: "Research",
      defaultFlex: 0.3,
      render: ({ value }) => (value == 1 ? "Yes" : "No"),
    },
    ...(userData.role === process.env.REACT_APP_ADMIN
      ? [
          {
            name: "retrival",
            header: "Manual Retrival",
            defaultFlex: 0.4,
            sortable: false,
            render: ({ data }) => {
              return (
                <div className="flex justify-center">
                  <button
                    title="News Retrival"
                    data-id={data.active_company_meta_id}
                    data-name={data.name}
                    onClick={runNewsRetrival}
                    className="mr-2 bg-gray-500 rounded-md p-0 border-gray-500 border-2 hover:bg-gray-600 hover:border-gray-600"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#fff"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                      <path d="M8 8l4 0" />
                      <path d="M8 12l4 0" />
                      <path d="M8 16l4 0" />
                    </svg>
                  </button>

                  <button
                    title="Video Retrival"
                    data-id={data.active_company_meta_id}
                    data-name={data.name}
                    onClick={runVideoRetrival}
                    className="bg-gray-500 rounded-md p-0 border-gray-500 border-2 hover:bg-gray-600 hover:border-gray-600"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#fff"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                      <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                    </svg>
                  </button>
                </div>
              );
            },
          },
        ]
      : []),
    {
      name: "action",
      header: "Disable",
      defaultFlex: 0.2,
      sortable: false,
      render: ({ data }) => {
        return (
          <div className="flex justify-center">
            <button
              title="Disable Company"
              data-status={data.status}
              data-id={data.id}
              data-name={data.name}
              onClick={handleDisableDialog}
              className="bg-rose-500 rounded-md p-0 border-rose-500 border-2 hover:bg-rose-600 hover:border-rose-600"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="4" y1="7" x2="20" y2="7" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ModalFeedback
        id="confirm-delete-user"
        modalOpen={dangerModalOpen}
        setModalOpen={setDangerModalOpen}
      >
        <div className="p-5 flex space-x-4">
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
            <svg
              className="w-4 h-4 shrink-0 fill-current text-rose-500"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          <div>
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800">
                Disable {dangerModalData.name}?
              </div>
            </div>
            <div className="text-sm mb-10">
              <div className="space-y-2 mr-2">
                <p>
                  Disabling the company will stop all automated analytic
                  retrievals until it's activated again.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                data-id={dangerModalData.id}
                data-status={dangerModalData.status}
                onClick={handleDisable}
              >
                Yes, Disable
              </button>
            </div>
          </div>
        </div>
      </ModalFeedback>
      <BaseTitle heading="Active Companies" />

      <BaseCard>
        <p className="mb-4">
          If the "processing" tag is assigned to the client, it indicates that
          the dashboard is still being prepared and could take up to 10 minutes
          to become available. It is recommended to refresh the page after the
          allotted time to see the dashboard link.
        </p>
        <p>
          If you are unable to locate the company dashboard, please enable the
          analytics for the company{" "}
          <Link className="font-bold" to="/companies">
            here.
          </Link>
        </p>
      </BaseCard>

      <BaseTable
        className="mt-8"
        style={{ minHeight: 740 }}
        defaultSortInfo={defaultSortInfo}
        loading={loading}
        loadingText="Fetching active companies"
        idProperty="active-companies-table"
        pagination={true}
        enableFiltering
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={companies}
      />
    </>
  );
};

export default ActiveCompanies;
