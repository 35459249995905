import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import PropTypes from "prop-types";

import axios from "../../api/AxiosHttp";
import BaseCard from "../../components/base/BaseCard";
import BaseStats from "../../components/base/BaseStats";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";
import DoughnutChart from "../../components/charts/DoughnutChart";
import Tippy from "../../lib/Tipy";
import VideosSummaryChart from "../../partials/analytics/videos/VideosSummaryChart";
import VideosTable from "../../partials/analytics/videos/VideosTable";
import { updateVideoData } from "../../redux/videoSlice";
import { updatePrimisVideoData } from "../../redux/primisSlice";
import { toCamel, formatDate, minTommss } from "../../utils/Utils";

// Set partial prop true to only show summary section
const Videos = ({ partial = false }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { videoData } = useSelector((state) => state.video);
  const { primisVideoData } = useSelector((state) => state.primis);
  const { companyData } = useSelector((state) => state.company);
  const { userData } = useSelector((state) => state.auth);
  const [loadingPage, setLoadingPage] = useState(false);

  const totalViewDesc = (partial ? 'Sourced from YouTube Analytics and Video Network. Updated daily, the total number of views your videos have received since they were published.' : 'Sourced from YouTube Analytics and updated daily, the total number of views your videos have received since they were published.');

  useEffect(() => {
    getSummary();
    Tippy("[data-tippy-content]");
  }, [companyData]);

  const logActivity = () => {
    if (
      !partial &&
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_videos_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_videos",
        type: "company",
      });
      sessionStorage.setItem("view_videos_" + companyData.company_id, true);
    }
  };

  // Function to get video data from API and update redux store if older than 5 minutes
  const getSummary = async () => {
    logActivity();

    if (
      videoData &&
      videoData.active_company_id &&
      videoData.active_company_id == id &&
      Date.now() - videoData.timestamp < 300000
    ) {
      return;
    }

    setLoadingPage(true);

    if (companyData?.playlist == 0) {
      dispatch(updateVideoData({ status: 0 }));
      setLoadingPage(false);
      return;
    }

    dispatch(updateVideoData({}));

    try {
      const endPoint = `/api/companies/active/${id}/youtube`;
      const { data } = await axios.get(endPoint);

      dispatch(updateVideoData({ ...data, timestamp: Date.now() }));
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      dispatch(updateVideoData({ status: 0 }));
    }
  };

  var ageChartDataColors = [
    "#12495D",
    "#FFA100",
    "#FF7338",
    "#FF5167",
    "#E44289",
    "#B9419D",
    "#6B4F96",
  ];
  const ageChartData = {
    series: [
      {
        name: "Age Ranges",
        data:
          "age" in videoData
            ? videoData.age.map((item, index) => ({
                name: toCamel(item.key),
                y: parseFloat(item.value),
                color: ageChartDataColors[index],
              }))
            : [],
      },
    ],
  };

  var genderChartDataColors = ["#369440", "#A0BF4D", "#D9DD3F"];
  const genderChartData = {
    series: [
      {
        name: "Gender",
        data:
          "gender" in videoData
            ? videoData.gender.map((item, index) => ({
                name: toCamel(item.key),
                y: parseFloat(item.value),
                color: genderChartDataColors[index],
              }))
            : [],
      },
    ],
  };

  const visitorCountriesTable = [
    { name: "key", header: "Country", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  const externalSourcesTable = [
    { name: "key", header: "Sources", defaultFlex: 1.1 },
    {
      name: "value",
      header: "%",
      defaultFlex: 0.3,
      render: ({ data }) => {
        return `${data.value}%`;
      },
    },
  ];

  return (
    <>
      <BaseTitle
        heading="Videos"
        type={!partial ? "h1" : "h2"}
        subtitle={!partial && companyData.name}
        linkTo={partial && `/analytics/${id}/videos`}
      />
      {!partial && (
        <BaseCard>
          <p className="mb-3">
            Video content is hosted on our{" "}
            <a
              href="https://www.youtube.com/@Proactive247"
              rel="noreferrer"
              className="font-bold"
              target="_blank"
            >
              YouTube channel
            </a>{" "}
            and YouTube makes analytics available to us, YouTube needs time to
            verify each view therefore you need to wait 72 hours before checking
            on a video's performance.
          </p>
          <p>
            We track the number of views each video has received, the average
            time it is watched and the total watch time. We also chart these
            views over time and measure external referral traffic sources to
            help you understand how visitors are arriving at your content.
            Access your videos within your playlist on{" "}
            <a
              href={
                "https://www.youtube.com/playlist?list=" + companyData?.playlist
              }
              className="font-bold"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>
            .
          </p>
        </BaseCard>
      )}

      {!loadingPage ? (
        <>
          {videoData &&
          Object.keys(videoData).length > 0 &&
          videoData?.status !== 0 &&
          companyData?.playlist !== 0 ? (
            <>
              <div className="grid grid-cols-12 xl:gap-8">
                <VideosSummaryChart partial={partial} data={videoData} />

                <div className="col-span-12 xl:col-span-2 xl:mb-8">
                  <div className="grid gap-x-8 grid-cols-1 sm:grid-cols-2 xl:grid-cols-1">
                    <BaseStats
                      name="Total Views"
                      subtext={
                        "(As of " +
                        formatDate(
                          new Date().setDate(new Date().getDate() - 3)
                        ) +
                        ")"
                      }
                      number={partial ? primisVideoData.total_views : videoData.total_views}
                      data-tippy-content={ totalViewDesc }
                    />
                    <BaseStats
                      name="Total Watch Time"
                      subtext={
                        "(As of " +
                        formatDate(
                          new Date().setDate(new Date().getDate() - 3)
                        ) +
                        ")"
                      }
                      number={videoData.total_watch_time}
                      numberFormatting={false}
                      data-tippy-content="Sourced from YouTube Analytics and updated daily, the total watch time for your videos displayed in hours."
                    />
                    <BaseStats
                      className="xl:mb-0"
                      name="Avg View Duration"
                      number={minTommss(
                        (videoData.total_watch_time * 60) /
                          videoData.total_views
                      )}
                      numberFormatting={false}
                      data-tippy-content="The total watch time of your YouTube video content, divided by the total number of views it received, to show the average view duration displayed in minutes and seconds."
                    />
                  </div>
                </div>
              </div>
              {partial && (
                <>
                  <div className="grid grid-cols-12 gpa-0 md:gap-8 xl:mt-8">
                    <div className="col-span-12 md:col-span-6">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from YouTube Analytics and Google Analytics 4, this is an averaged total of the videos in your unique company playlist."
                        >
                          Top 5 Viewer Countries
                        </h4>
                        <BaseTable
                          idProperty="visitor-countries"
                          dataSource={
                            videoData.countries ? videoData.countries : []
                          }
                          columns={visitorCountriesTable}
                          style={{ minHeight: 250 }}
                          className="no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <BaseCard>
                        <h4
                          className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                          data-tippy-content="Sourced from YouTube Analytics and updated weekly, this is an averaged total of all videos and their external referral sources of traffic."
                        >
                          Top 5 External Referral
                        </h4>
                        <BaseTable
                          idProperty="traffic-source"
                          dataSource={
                            videoData.traffic_source
                              ? videoData.traffic_source
                              : []
                          }
                          columns={externalSourcesTable}
                          style={{ minHeight: 250 }}
                          className="no-border"
                          scrollHide={false}
                        />
                      </BaseCard>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-0 gap-x-8 xl:gap-8 mb-4">
                    <BaseCard>
                      <h4
                        className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                        data-tippy-content="Sourced from YouTube Analytics and updated weekly, this is an averaged total across all your videos of the age ranges of your viewers."
                      >
                        Age Ranges
                      </h4>
                      {"age" in videoData ? (
                        <DoughnutChart
                          options={ageChartData}
                          chartType="videoAgeDoughnut"
                        />
                      ) : (
                        <div className="w-full h-full flex flex-col justify-start items-left">
                          <div
                            className="w-full h-full flex items-center justify-center font-bold"
                            style={{
                              borderRadius: "100%",
                              border: "35px solid #f1f5f9",
                              width: "230px",
                              height: "230px",
                              marginTop: "1rem",
                            }}
                          >
                            Not enough data
                          </div>
                        </div>
                      )}
                    </BaseCard>
                    <BaseCard>
                      <h4
                        className="text-xl mb-4 uppercase text-violet font-bold w-fit"
                        data-tippy-content="Sourced from YouTube Analytics and updated weekly, this is an averaged total across all your videos of the identified gender of your viewers."
                      >
                        Gender
                      </h4>

                      {"gender" in videoData ? (
                        <DoughnutChart
                          options={genderChartData}
                          chartType="videoGenderDoughnut"
                        />
                      ) : (
                        <div className="w-full h-full flex flex-col justify-start items-left">
                          <div
                            className="w-full h-full flex items-center justify-center font-bold"
                            style={{
                              borderRadius: "100%",
                              border: "35px solid #f1f5f9",
                              width: "230px",
                              height: "230px",
                              marginTop: "1rem",
                            }}
                          >
                            Not enough data
                          </div>
                        </div>
                      )}
                    </BaseCard>
                  </div>
                </>
              )}
            </>
          ) : (
            <BaseCard className="mb-8">There is no data for videos.</BaseCard>
          )}

          {!partial &&
            videoData?.items &&
            videoData?.status !== 0 &&
            companyData?.playlist !== 0 && (
              <VideosTable tableData={videoData.items} />
            )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

Videos.propTypes = {
  partial: PropTypes.bool,
};

export default Videos;
