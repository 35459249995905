import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";

const UnprotectedRoute = ({ children }) => {
  const { isLoggedIn, currentCompanyId } = useSelector(
    (state) => state.auth
  );

  if (isLoggedIn) {
    if (currentCompanyId) {
      return <Navigate to={`/analytics/${currentCompanyId}`} replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return children ? children : <Outlet />;
};

export default UnprotectedRoute;
