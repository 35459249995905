import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router";
import { ThreeDots } from "react-loader-spinner";

import { updateIntroData } from "../../../redux/introSlice";
import axios from "../../../api/AxiosHttp";

const DashIntro = () => {
  const { id } = useParams();

  const { articleData } = useSelector((state) => state.article);
  const { companyData } = useSelector((state) => state.company);
  const { introData } = useSelector((state) => state.intro);

  const [loading, setLoading] = useState(false);

  const { latest_visitors } = articleData;

  const dispatch = useDispatch();

  useEffect(() => {
    fetchIntroFigures();
  }, [companyData]);

  const fetchIntroFigures = async () => {
    if (
      introData &&
      introData.active_company_id &&
      introData.active_company_id == id &&
      Date.now() - introData.timestamp < 300000
    ) {
      return;
    }

    setLoading(true);
    dispatch(updateIntroData({}));

    try {
      const endPoint = `api/companies/active/${id}/countries`;
      const { data } = await axios.get(endPoint);
      dispatch(updateIntroData({ ...data, timestamp: Date.now() }));
      setLoading(false);
    } catch (error) {
      dispatch(updateIntroData({ status: 0 }));
      setLoading(false);
    }
  };

  let topThreeVisitors = "";
  if (latest_visitors && latest_visitors.length) {
    for (let i = 0; i < 3 && i < latest_visitors.length; i++) {
      topThreeVisitors += latest_visitors[i].name;
      if (i < 2 && i < latest_visitors.length - 1) {
        topThreeVisitors += ", ";
      }
    }
  }

  return (
    <div className="mt-12">
      {!loading ? (
        <>
          <p className="mb-3">
            Welcome to Proactive Analytics, your dashboard for analytics and
            metrics on your content appearing on our websites.
          </p>
          <p className="mb-3">
            Cumulatively across all of the media published,{" "}
            {introData?.items?.length > 0 && (
              <>
                <strong>{`${introData.items[0].value}%`}</strong> of visits to{" "}
                {companyData.name && companyData.name} originated in{" "}
                <strong>{introData.items[0].key}</strong>
              </>
            )}
            {introData?.items?.length > 1 && (
              <>
                , followed by <strong>{introData.items[1].key}</strong> with{" "}
                <strong>{`${introData.items[1].value}%`}</strong>
              </>
            )}
            {introData?.items?.length > 2 && (
              <>
                , and <strong>{introData.items[2].key}</strong> with{" "}
                <strong>{`${introData.items[2].value}%`}</strong>
              </>
            )}
            .
          </p>

          {topThreeVisitors && (
            <p>
              We are also able to identify interest in your articles from{" "}
              {topThreeVisitors} which you can view in more detail{" "}
              <Link
                to={`/analytics/${companyData.id}/forensics`}
                className="font-bold"
              >
                here
              </Link>
              .
            </p>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default DashIntro;
