import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { ThreeDots } from "react-loader-spinner";
import axios from "../../api/AxiosHttp";
import BaseButton from "../../components/base/BaseButton";
import DetailedStats from "../../components/DetailedStats";
import BaseCard from "../../components/base/BaseCard";
import BaseTitle from "../../components/base/BaseTitle";
import Tippy from "../../lib/Tipy";
import Articles from "./Articles";
import Videos from "./Videos";
import Primis from "./Primis";
import PerformanceTable from "../../partials/analytics/dashboard/PerformanceTable";
import DashIntro from "../../partials/analytics/dashboard/DashIntro";
import PdfDocument from "../../partials/analytics/pdf/PdfDocument";
import ActivityMenu from "../../components/DropdownActivity";

const Dashboard = () => {
  const { id } = useParams();
  const { companyData } = useSelector((state) => state.company);
  const { articleData } = useSelector((state) => state.article);
  const { primisVideoData } = useSelector((state) => state.primis);
  const { videoData } = useSelector((state) => state.video);
  const { userData } = useSelector((state) => state.auth);
  const { introData } = useSelector((state) => state.intro);
  const [PdfLoader, setPdfLoader] = useState(true);

  useEffect(() => {
    Tippy("[data-tippy-content]");
    const checkDataReady = () => {
      const isCompanyDataReady = companyData.id == id ? true : false;
      const isArticleDataReady =
        articleData.status === 0
          ? true
          : articleData.active_company_id == id
          ? true
          : false;
      const isVideoDataReady =
        videoData.status === 0
          ? true
          : videoData.active_company_id == id
          ? true
          : false;
      const isPrimisVideoDataReady =
        primisVideoData.status === 0
          ? true
          : primisVideoData.active_company_id === companyData.company_id
          ? true
          : false;
      const isIntroDataReady =
        introData.status === 0
          ? true
          : introData.active_company_id == id
          ? true
          : false;

      const isDataReady =
        isCompanyDataReady &&
        isArticleDataReady &&
        isVideoDataReady &&
        isPrimisVideoDataReady &&
        isIntroDataReady;

      if (isDataReady) {
        setPdfLoader(false);
      }
    };

    checkDataReady();
  }, [companyData, articleData, videoData, primisVideoData, introData]);

  const { logo, name, ticker } = companyData;
  
  const videoViews = primisVideoData.total_views ? primisVideoData.total_views : 0;

  const generatePdfDocument = async (fileName) => {
    setPdfLoader(true);
    let articleSummaryChart = null;
    let articleDeviceDoughnut = null;
    let articleNewReturnDoughnut = null;
    let videoSummaryChart = null;
    let videoAgeDoughnut = null;
    let videoGenderDoughnut = null;

    while (
      !articleSummaryChart ||
      !articleDeviceDoughnut ||
      !articleNewReturnDoughnut ||
      !videoSummaryChart ||
      !videoAgeDoughnut ||
      !videoGenderDoughnut
    ) {
      articleSummaryChart =
        sessionStorage.getItem("articleSummaryChart" + companyData.id) &&
        JSON.parse(
          sessionStorage.getItem("articleSummaryChart" + companyData.id)
        );
      articleDeviceDoughnut =
        sessionStorage.getItem("articleDeviceDoughnut" + companyData.id) &&
        JSON.parse(
          sessionStorage.getItem("articleDeviceDoughnut" + companyData.id)
        );
      articleNewReturnDoughnut =
        sessionStorage.getItem("articleNewReturnDoughnut" + companyData.id) &&
        JSON.parse(
          sessionStorage.getItem("articleNewReturnDoughnut" + companyData.id)
        );
      videoSummaryChart =
        videoData.status !== 0
          ? sessionStorage.getItem("videoSummaryChart" + companyData.id) &&
            JSON.parse(
              sessionStorage.getItem("videoSummaryChart" + companyData.id)
            )
          : true;
      videoAgeDoughnut =
        videoData.status !== 0
          ? sessionStorage.getItem("videoAgeDoughnut" + companyData.id) &&
            JSON.parse(
              sessionStorage.getItem("videoAgeDoughnut" + companyData.id)
            )
          : true;

      videoGenderDoughnut =
        videoData.status !== 0
          ? sessionStorage.getItem("videoGenderDoughnut" + companyData.id) &&
            JSON.parse(
              sessionStorage.getItem("videoGenderDoughnut" + companyData.id)
            )
          : true;

      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (userData.role === process.env.REACT_APP_CLIENT) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "export_pdf",
        type: "company",
      });
    }

    const blob = await pdf(
      <PdfDocument
        data={{
          companyData,
          articleData,
          videoData,
          primisVideoData,
          introData,
          articleSummaryChart,
          articleDeviceDoughnut,
          articleNewReturnDoughnut,
          videoSummaryChart,
          videoAgeDoughnut,
          videoGenderDoughnut,
        }}
      />
    ).toBlob();
    saveAs(blob, fileName);
    setPdfLoader(false);
  };

  return (
    <>
      <BaseTitle
        heading="Dashboard"
        rightSideContent={
          <div>
            {(userData.role == process.env.REACT_APP_STAFF ||
              userData.role == process.env.REACT_APP_ADMIN) &&
              companyData.users &&
              companyData.users.length > 0 && <ActivityMenu />}

            <BaseButton
              onClick={() =>
                generatePdfDocument(name + " - Proactive Analytics.pdf")
              }
              label="Download PDF"
              type="submit"
              loading={PdfLoader}
            />
          </div>
        }
      />

      <BaseCard className="py-0 px-0">
        {Object.keys(companyData).length > 1 && companyData?.id == id ? (
          <div className="p-6">
            <div className="grid grid-rows-[auto, auto] lg:grid-rows-1 lg:grid-cols-2 gap-x-8">
              <div className="flex mb-6 lg:mb-0">
                <div className="w-[96px] min-w-[96px] lg:w-[132px] lg:min-w-[132px] block mr-6 flex items-center">
                  <img width="132" height="132" src={logo} alt={name} />
                </div>

                <div className="flex items-center">
                  <h2 className="font-medium text-violet text-3xl">
                    {name}
                    {ticker && (
                      <span className="block text-lg font-bold">
                        ({ticker})
                      </span>
                    )}
                  </h2>
                </div>
              </div>
              <div className="flex flex-wrap lg:flex-nowrap lg:justify-end">
                {articleData.total_views + articleData.total_impressions >
                  0 && (
                  <DetailedStats
                    label="Total Views and Impressions"
                    value={
                      articleData.total_views +
                      articleData.total_impressions +
                      videoViews
                    }
                    data-tippy-content="Sourced from Google Analytics 4 (article views), Google Search Console (article impressions) and YouTube Analytics (video views) and updated daily, the reach of your Proactive content measured from our own channels."
                  />
                )}
                {articleData.total_views +
                  articleData.total_impressions +
                  videoViews >
                  0 &&
                  videoViews != 0 && (
                    <DetailedStats
                      label="Article Views and Impressions"
                      value={
                        articleData.total_views + articleData.total_impressions
                      }
                      data-tippy-content="A combination of page views data sourced from Google Analytics 4 and impressions data sourced from Google Search Console."
                    />
                  )}
                {videoViews > 0 && (
                  <DetailedStats
                    label="Video Views"
                    value={videoViews}
                    data-tippy-content="Sourced from YouTube Analytics and Video Network. Updated daily, the total number of views your videos have received since they were published."
                  />
                )}
              </div>
            </div>
            <DashIntro />
          </div>
        ) : (
          <div className="flex flex-grow justify-center items-center">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#e94a3e"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </BaseCard>

      <Articles partial={true} />
      <Videos partial={true} />
      <Primis partial={true} />
    </>
  );
};

export default Dashboard;
