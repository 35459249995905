import React, { useState } from "react";
import { NavLink, useLocation } from "react-router";

import SidebarLinkGroup from "./SidebarLinkGroup";

const AdminLinks = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-sm uppercase text-white font-semibold pl-3">
          <span className="block">Admin</span>
        </h3>
        <ul className="mt-3">
          {/* Internal Analytics */}
          <SidebarLinkGroup
            activecondition={
              pathname.includes("google") ||
              pathname.includes("youtube") ||
              pathname.includes("client") ||
              pathname.includes("news-lookup") ||
              pathname.includes("primis")
            }
          >
            {(handleClick, open) => {
              return (
                <>
                  <a
                    href="#0"
                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("google") ||
                      pathname.includes("youtube") ||
                      pathname.includes("news-lookup") ||
                      pathname.includes("primis") ||
                      (pathname.includes("client") && "hover:text-slate-200")
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick();
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          viewBox="0 0 29.38 24.437"
                          className="shrink-0 h-7 w-7"
                        >
                          <g transform="translate(4.489 0.23)">
                            <path
                              d="M-1-1,6,6"
                              transform="translate(18.184 17.5)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="2"
                              className={`stroke-current text-white ${
                                pathname.includes("google") ||
                                pathname.includes("youtube") ||
                                pathname.includes("client") ||
                                pathname.includes("news-lookup") ||
                                pathname.includes("primis")
                                  ? "!text-primary"
                                  : ""
                              }`}
                            />
                            <path
                              d="M8,6.192h3.846l2.154-4,3,6,3-4,3,4,2-3,1,1h3"
                              transform="translate(-12.489 4.77)"
                              fill="none"
                              stroke="#fff"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              className={`stroke-current text-white ${
                                pathname.includes("google") ||
                                pathname.includes("youtube") ||
                                pathname.includes("client") ||
                                pathname.includes("news-lookup") ||
                                pathname.includes("primis")
                                  ? "!text-primary"
                                  : ""
                              }`}
                            />
                            <path
                              d="M3.484,3.892A9.5,9.5,0,0,1,10.394,1a9.7,9.7,0,1,1,0,19.4,9.815,9.815,0,0,1-8.782-5.573"
                              transform="translate(-0.335 -0.23)"
                              fill="none"
                              stroke="#fff"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className={`stroke-current text-white ${
                                pathname.includes("google") ||
                                pathname.includes("youtube") ||
                                pathname.includes("client") ||
                                pathname.includes("news-lookup") ||
                                pathname.includes("primis")
                                  ? "!text-primary"
                                  : ""
                              }`}
                            />
                          </g>
                        </svg>

                        <span className="text-base font-regular ml-3 duration-200">
                          Internal Analytics
                        </span>
                      </div>

                      <div className="flex shrink-0 ml-2">
                        <svg
                          className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                            open && "rotate-180"
                          }`}
                          viewBox="0 0 12 12"
                        >
                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className="block">
                    <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/google"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Google
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/youtube"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Youtube
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/client"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Client
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/news-lookup"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            News Lookup
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/primis"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Video Network
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
              );
            }}
          </SidebarLinkGroup>

          {/* Companies */}
          <SidebarLinkGroup activecondition={pathname.includes("companies")}>
            {(handleClick, open) => {
              return (
                <>
                  <a
                    href="#0"
                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("companies") && "hover:text-slate-200"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick();
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          viewBox="0 0 26.534 25.703"
                          className="shrink-0 h-6 w-6"
                        >
                          <g
                            fill="none"
                            stroke="#fff"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                            className={`stroke-current text-white ${
                              pathname.includes("companies") && "!text-primary"
                            }`}
                          >
                            <path
                              d="m14.441 3.93 7.893-2.663 2.933 8.695-7.893 2.663z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("companies") &&
                                "!text-primary"
                              }`}
                            />
                            <path
                              d="m9.063 7.102 5.79-1.953 2.11 6.257-5.789 1.953z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("companies") &&
                                "!text-primary"
                              }`}
                            />
                            <path
                              d="m2.704 10.889 6.86-2.316 1.117 3.31-6.86 2.316z"
                              strokeWidth="2.0011"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("companies") &&
                                "!text-primary"
                              }`}
                            />
                            <path
                              d="m1.267 11.046 1.338-.451 1.315 3.899-1.338.451z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("companies") &&
                                "!text-primary"
                              }`}
                            />
                            <path
                              d="m7.263 25.268 6.06-12.5 5.94 12.5"
                              strokeLinejoin="round"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("companies") &&
                                "!text-primary"
                              }`}
                            />
                          </g>
                        </svg>

                        <span className="text-base font-regular ml-3 duration-200">
                          Companies
                        </span>
                      </div>
                      {/* Icon */}
                      <div className="flex shrink-0 ml-2">
                        <svg
                          className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                            open && "rotate-180"
                          }`}
                          viewBox="0 0 12 12"
                        >
                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className="block">
                    <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/companies"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Companies List
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/companies/active"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 duration-200">
                            Active Companies
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
              );
            }}
          </SidebarLinkGroup>
          {/* Users */}
          <SidebarLinkGroup activecondition={pathname.includes("user")}>
            {(handleClick, open) => {
              return (
                <>
                  <a
                    href="#0"
                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("user") && "hover:text-slate-200"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick();
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          className="shrink-0 h-6 w-6"
                          viewBox="0 0 26 20.988"
                        >
                          <g fill="none" stroke="#fff" strokeWidth="2">
                            <path
                              d="M17.874 20.987v-8.76c0-1.558-3.181-2.658-4.874-2.658s-4.874 1.1-4.874 2.658v8.76"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                            <path
                              data-name="Path 1327"
                              d="M13 6.317A2.153 2.153 0 0 0 15.216 4.1v-.885a2.216 2.216 0 1 0-4.431 0V4.1A2.153 2.153 0 0 0 13 6.317Z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                            <path
                              data-name="Path 1329"
                              d="M25 18.91v-4.874c0-1.558-3.181-2.658-4.874-2.658a7.473 7.473 0 0 0-2.212.406"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                            <path
                              data-name="Path 1330"
                              d="M20.126 8.126a2.153 2.153 0 0 0 2.216-2.217v-.885a2.216 2.216 0 1 0-4.431 0v.885a2.153 2.153 0 0 0 2.215 2.217Z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                            <path
                              data-name="Path 1331"
                              d="M1 18.91v-4.874c0-1.558 3.181-2.658 4.874-2.658a7.473 7.473 0 0 1 2.212.406"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                            <path
                              data-name="Path 1332"
                              d="M5.873 8.126a2.153 2.153 0 0 1-2.215-2.217v-.885a2.153 2.153 0 0 1 2.215-2.215 2.153 2.153 0 0 1 2.215 2.215v.885a2.153 2.153 0 0 1-2.215 2.217Z"
                              className={`group-hover:text-primary stroke-current text-white transition duration-250 ${
                                pathname.includes("user") && "!text-primary"
                              }`}
                            />
                          </g>
                        </svg>

                        <span className="text-base font-regular ml-3 duration-200">
                          Users
                        </span>
                      </div>
                      {/* Icon */}
                      <div className="flex shrink-0 ml-2">
                        <svg
                          className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                            open && "rotate-180"
                          }`}
                          viewBox="0 0 12 12"
                        >
                          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className="2xl:block">
                    <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/users"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 2xl:block duration-200">
                            Users List
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/user/add"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 2xl:block duration-200">
                            Add User
                          </span>
                        </NavLink>
                      </li>
                      <li className="mb-1 last:mb-0">
                        <NavLink
                          end
                          to="/activity-overview"
                          className={({ isActive }) =>
                            "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                            (isActive ? "!text-primary" : "")
                          }
                        >
                          <span className="text-sm font-regular ml-3 2xl:block duration-200">
                            Client Activity
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
              );
            }}
          </SidebarLinkGroup>
        </ul>
      </div>
    </div>
  );
};

export default AdminLinks;
