import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";

import BaseButton from "./base/BaseButton";
import Transition from "../utils/Transition";

function DropdownActivity({ align }) {
  const { companyData } = useSelector((state) => state.company);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <BaseButton
        label="Activity"
        type="button"
        ref={trigger}
        className="inline-flex justify-center items-center group mr-4"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        dropdown={true}
      />

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {companyData.users.length > 0 &&
              companyData.users.map((user, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="font-medium text-sm text-primary hover:text-primary-dark flex items-center py-1 px-3"
                      to={`/user/${user.id}/activity`}
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                      {user.name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownActivity;
