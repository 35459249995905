import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import axios from "../../api/AxiosHttp";
import BaseButton from "../../components/base/BaseButton";
import BaseCard from "../../components/base/BaseCard";
import BaseTable from "../../components/base/BaseTable";
import BaseTitle from "../../components/base/BaseTitle";
import { updateForensicData } from "../../redux/forensicSlice";

const Forensics = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [gridRef, setGridRef] = useState(null);
  const { companyData } = useSelector((state) => state.company);
  const { forensicData } = useSelector((state) => state.forensic);
  const { userData } = useSelector((state) => state.auth);

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    getSummary();
  }, [companyData]);

  const logActivity = () => {
    if (
      userData.role === process.env.REACT_APP_CLIENT &&
      !sessionStorage.getItem("view_lf_" + companyData.company_id)
    ) {
      const endPoint = `/api/users/${userData.id}/activity`;
      axios.post(endPoint, {
        model_id: companyData.company_id,
        action: "view_lf",
        type: "company",
      });
      sessionStorage.setItem("view_lf_" + companyData.company_id, true);
    }
  };

  // Function to get forensic data from API and update redux store if older than 5 minutes
  const getSummary = async () => {
    logActivity();

    if (
      forensicData &&
      forensicData.active_company_id &&
      forensicData.active_company_id == id &&
      Date.now() - forensicData.timestamp < 300000
    ) {
      return;
    }

    dispatch(updateForensicData({}));
    setLoadingPage(true);

    try {
      const endPoint = `/api/companies/active/${id}/lf`;
      const { data } = await axios.get(endPoint);

      dispatch(updateForensicData({ ...data, timestamp: Date.now() }));
      setLoadingPage(false);
    } catch (error) {
      dispatch(updateForensicData({ status: 0 }));
      setLoadingPage(false);
    }
  };

  const exportPDF = async () => {
    try {
      const endPoint = `/api/companies/active/${id}/excel`;
      const { data } = await axios.get(endPoint);

      const link = document.createElement("a");
      const url = data;

      link.setAttribute("href", url);
      link.style.position = "absolute";
      link.style.visibility = "hidden";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {}
  };

  // ReactDataGrid settings
  const defaultSortInfo = { name: "timestamp", dir: -1 };

  const defaultFilterValue = [
    { name: "title", operator: "contains", type: "string", value: "" },
    { name: "name", operator: "contains", type: "string", value: "" },
    { name: "industry", operator: "inlist", type: "select", value: "" },
    { name: "country", operator: "inlist", type: "select", value: "" },
  ];

  const industries =
    forensicData &&
    Object.keys(forensicData).length > 0 &&
    forensicData.status !== 0
      ? forensicData.items.map((item) => item.industry)
      : [];

  const uniqueIndustries = industries
    .filter((item, index) => industries.indexOf(item) === index)
    .sort();

  const countries =
    forensicData &&
    Object.keys(forensicData).length > 0 &&
    forensicData.status !== 0
      ? forensicData.items.map((item) => item.country)
      : [];

  const uniqueCountries = countries
    .filter((item, index) => countries.indexOf(item) === index)
    .sort();

  const columns = [
    {
      name: "timestamp",
      header: "Last Visited",
      defaultFlex: 0.4,
      render: ({ data }) => {
        return data.date;
      },
    },
    // {
    //   name: "title",
    //   header: "Article",
    //   defaultFlex: 0.5,
    //   render: ({ data }) => {
    //     return <span title={data.title}>{data.title}</span>;
    //   },
    // },
    { name: "name", header: "Company", defaultFlex: 0.5 },
    { name: "website", header: "Website", defaultFlex: 0.5 },
    {
      name: "industry",
      header: "Industry",
      defaultFlex: 0.35,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        dataSource: uniqueIndustries.map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "referrer",
      header: "Referrer",
      defaultFlex: 0.45,
    },
    {
      name: "pages",
      header: "Visits",
      defaultFlex: 0.2,
    },
    { name: "town", header: "Town", defaultFlex: 0.35 },
    {
      name: "country",
      header: "Country",
      defaultFlex: 0.35,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        dataSource: uniqueCountries.map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "url",
      header: "View",
      defaultFlex: 0.2,
      sortable: false,
      render: ({ data }) => {
        return (
          <div className="flex justify-left">
            <a
              href={data.url}
              title={data.title}
              target="_blank"
              className="bg-gray-500 rounded-md p-0 border-gray-500 border-2 hover:bg-gray-600 hover:border-gray-600"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="2" />
                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
              </svg>
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <BaseTitle heading="Corporate Visitors" subtitle={companyData.name} />

      <BaseCard>
        <p className="mb-4">
          Proactive's websites also use a 3rd party service to identify some of
          the businesses visiting your content our website. The nature of
          reverse IP tracking (knowing which IP belongs to which business) means
          only a small percentage of overall visits are successfully tracked. It
          can however provide some useful insights and we include the list of
          all known visitors to your content below. You can also export the list
          as a Excel for offline viewing.
        </p>
        <p>
          The data available is from the last <strong>12 months</strong>.
        </p>
      </BaseCard>

      {!loadingPage ? (
        <>
          {forensicData &&
          Object.keys(forensicData).length > 0 &&
          forensicData.status !== 0 ? (
            <>
              <BaseTable
                handle={setGridRef}
                className="mt-8"
                style={{ minHeight: 740 }}
                idProperty="forensics-table"
                pagination={false}
                columns={columns}
                dataSource={forensicData?.items}
                enableFiltering
                defaultFilterValue={defaultFilterValue}
                defaultSortInfo={defaultSortInfo}
              />
              <BaseButton
                label="Export Excel"
                className="mt-6 self-start"
                onClick={exportPDF}
              />
            </>
          ) : (
            <BaseCard>There is no data for Corporate Visitors.</BaseCard>
          )}
        </>
      ) : (
        <div className="flex flex-grow justify-center items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#e94a3e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default Forensics;
