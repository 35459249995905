import { Link, useLocation } from "react-router";
import { useSelector } from "react-redux";

import CountryDropdown from "../../components/DropdownCompany";
import UserMenu from "../../components/DropdownProfile";

function Header({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();

  const { userData } = useSelector((state) => state.auth);

  return (
    <header className="sticky top-0 bg-white border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 2xl:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

            {userData.active_companies?.length > 0 && <CountryDropdown />}
          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            {location.pathname.startsWith("/analytics") &&
              userData.role !== process.env.REACT_APP_CLIENT && (
                <>
                  <div className="btn bg-light-grey-dark hover:bg-dark-grey hover:text-white text-dark-grey font-bold rounded-[20px] py-1.5">
                    <Link to="/companies/active">Back to companies list</Link>
                  </div>
                  {/*  Divider */}
                  <hr className="w-px h-6 bg-slate-200 mx-3" />
                </>
              )}
            <UserMenu align="right" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
