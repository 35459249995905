import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Outlet } from "react-router";

import axios from "../../api/AxiosHttp";
import { updateCompanyData } from "../../redux/companySlice";

const Analytics = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { companyData } = useSelector((state) => state.company);
  const { currentCompanyId } = useSelector((state) => state.auth);

  // Get the companyID from the URL and pass it onto child components if needed
  const companyID = id;
  // Call companySummary function on first load and everytime companyData state updates
  useEffect(() => {
    companySummary();
  }, [companyData, currentCompanyId]);

  // Function to get company data from API and update redux store if older than 5 minutes
  const companySummary = async () => {
    if (
      companyData &&
      companyData.id == companyID &&
      Date.now() - companyData.timestamp < 300000
    ) {
      return;
    }

    const endPoint = "/api/companies/active/" + companyID + "/data";
    const { data } = await axios.get(endPoint);

    dispatch(updateCompanyData({ ...data, timestamp: Date.now() }));
  };

  return <Outlet />;
};

export default Analytics;
